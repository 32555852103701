import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <img
        src="/under.gif"
        alt="Website Under Development"
        className="under-development-image"
      />
    </div>
  );
}

export default App;
